import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { MobileFooter } from '@/layouts/styled'
import MobileBack from '@/components/MobileBack'

const index: React.FC = () => {
  const { t } = useTranslation()

  const content = {
    pretitle: t('cases.freedom_super'),
    title: t('cases.freedom_title'),
    text: t('cases.freedom_body'),
    links: [
      {
        link: t('cases.freedom_cta_1'),
        url: '/clients-and-cases/cases',
        variant: 'all',
      },
    ],
  }

  return (
    <>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[<Text isMobile {...content} />]}
            >
              <Box row>
                <Box col={0.4}>
                  <Text {...content} />
                </Box>
                <Box col={0.6} />
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          variant="light"
          delay={0}
          time={1}
          url="/clients-and-cases"
          text="back"
        />
      </MobileFooter>
    </>
  )
}
export default index
